import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "ビジネスにも役立つ学術論文を読むメリットとは〜開発本部・ TechLunch〜",
  "date": "2017-01-24T09:20:28.000Z",
  "slug": "entry/2017/01/24/182028",
  "tags": ["medley"],
  "hero": "./2017_01_24.png",
  "heroAlt": "論文"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、`}<a parentName="p" {...{
        "href": "https://medley.life/"
      }}>{`オンライン病気事典 MEDLEY`}</a>{`の開発を担当しています徐聖博です。
メドレー開発本部では「TechLunch」という勉強会を隔週で開催しており、お互いのノウハウをシェアしています。今回私は、”学術論文を読む”ことについて発表しました。
開発者だけではなく、技術に興味がある人、更には社会では働く全ての人が知って得する内容だと思うので、是非読んでみてください。`}</p>
    <h1>{`なぜ徐が学術論文の読み方をお伝えするのか`}</h1>
    <p>{`今では、メドレーで日々エンジニアとして Web 開発をしている私ですが、
大学院時代は`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%C5%EC%B5%FE%C2%E7%B3%D8"
      }}>{`東京大学`}</a>{`大学院の`}<a parentName="p" {...{
        "href": "https://www.honiden.nii.ac.jp/"
      }}>{`本位田研究室`}</a>{`で、一流の研究者を志し"`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%BF%CD%B9%A9%C3%CE%C7%BD"
      }}>{`人工知能`}</a>{`"の研究をしていました。`}</p>
    <p>{`研究活動のひとつに"学術論文を書く"ということがあります。
自身が良い学術論文を書くためには、その分野の先行研究の"良い学術論文"に多く触れ、そこから研究内容や論文の書き方を学ぶ必要があります。
そのため、大学院時代は日々学術論文を読む生活を送っていました。
学術論文は、一定の読み方のルールやコツがあり、そこがハードルとなり読むことを挫折する人も少なくありません。
今回は「効果的な学術論文の読み方」や「どんな論文を読めば良いのか」を改めてまとめてみました。`}</p>
    <h1>{`そもそも、学術論文とは？`}</h1>
    <p>{`スタートアップで働く人やエンジニアと呼ばれる職種の人々は日々"技術記事"と称される、技術関連のノウハウやベストプラクティスが詰まった記事を読んだりしていると思います。`}</p>
    <h2>{`ノウハウやベストプラクティスが日々投稿されるサービス：Qiita`}</h2>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="Qiita - プログラマの技術情報共有サービス - Qiita" src="https://hatenablog-parts.com/embed?url=http%3A%2F%2Fqiita.com%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://qiita.com/">qiita.com</a></cite>
    <h2>{`困ったときに世界中の"わかる人"に質問をするサービス：StackOverflow`}</h2>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="Stack Overflow" src="https://hatenablog-parts.com/embed?url=http%3A%2F%2Fstackoverflow.com%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://stackoverflow.com/">stackoverflow.com</a></cite>
    <h2>{`その技術に精通している人が書くブログ：酒と泪と`}<a parentName="h2" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby"
      }}>{`Ruby`}</a>{`と`}<a parentName="h2" {...{
        "href": "https://d.hatena.ne.jp/keyword/Rails"
      }}>{`Rails`}</a>{`と`}</h2>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="酒と泪と Ruby と Rails と" src="https://hatenablog-parts.com/embed?url=http%3A%2F%2Fmorizyun.github.io%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://morizyun.github.io/">morizyun.github.io</a></cite>
    <p>{`一方、学術論文には`}</p>
    <ul>
      <li parentName="ul">{`最先端の研究発表成果が載っている`}</li>
      <li parentName="ul">{`第三者による査読がなされているので品質の担保がされている`}</li>
      <li parentName="ul">{`関連した周辺の研究についても知識が増える`}</li>
    </ul>
    <p>{`など、学術論文を読むことならではのメリットがあると考えています。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20170116/20170116165737.jpg",
        "alt": null
      }}></img></p>
    <h1>{`学術論文を読む意義とは`}</h1>
    <p>{`学術論文を読むのは必ずしもメリットばかりではありません。
特に、学術論文を読むのは初心者にとっては一苦労です。`}</p>
    <p>{`学術論文を読むデメリットを挙げると、`}</p>
    <ul>
      <li parentName="ul">{`いつ使えるかわからない技術が多い`}</li>
      <li parentName="ul">{`読むのに時間がかかる（良い論文は英語で書かれているものが多い！）`}</li>
      <li parentName="ul">{`高い費用がかかる`}</li>
    </ul>
    <p>{`特に、筆者が学生時代は毎日 1 本論文を読むことを努力目標にしていましたが、「いつ使えるかわからない技術が多い」という点で論文を読むのが本当に辛く感じていたのを今でも覚えています。`}</p>
    <p>{`しかし、そんな苦痛を乗り越えた丘の向こうにはもちろん素晴らしい世界が広がっているはずです。
例えば、`}</p>
    <ul>
      <li parentName="ul">{`うまくビジネスに使いこなせたら”技術”で勝負ができる`}</li>
      <li parentName="ul">{`ものごとを見る視点が増える`}</li>
      <li parentName="ul">{`純粋に知らない技術を知るのが面白い`}</li>
    </ul>
    <p>{`"技術"で勝負したい、というのが技術者として働く者全てに共通する気持ちだと思っています。
技術記事を読むのに似ていますが、自分の知らない知識をインプットすることは純粋に楽しいことです。
また、学術論文では一つの物事を様々な角度からアプローチしているため物事を見る視点が増えます。
最終的に、まだビジネスに応用されていない技術を誰より先にビジネスに活かし、それで成功することは技術者にとってこれほど嬉しいことはないと信じています。`}</p>
    <h1>{`勉強会の様子`}</h1>
    <p>{`ここまでお話しした内容も含め、勉強会ではこんな内容を発表しました。`}</p>
    <p>{`発表資料は`}<a parentName="p" {...{
        "href": "https://speakerdeck.com/medley/medorekai-fa-bu-techlunch-xue-shu-lun-wen-wodu-ntemiyou"
      }}>{`こちら`}</a></p>
    <p>{`特に、自分が卒業した本位田研究室に伝わる論文のポイントは、
新規事業を始める際にも使える重要な視点だと思っており、CTO の平山も「うんうん」と深くうなずくき共感してもらえました。`}</p>
    <h2>{`本位田研究室に伝わる論文のポイント`}</h2>
    <ul>
      <li parentName="ul">{`解くべき問題は何か？`}</li>
      <li parentName="ul">{`なぜその問題を解く必要があるのか？`}</li>
      <li parentName="ul">{`その問題は過去に解かれていないのか？`}</li>
      <li parentName="ul">{`なぜ解かれていないのか？`}</li>
      <li parentName="ul">{`アイディアの着眼点は何か？`}</li>
      <li parentName="ul">{`具体的なアイディアは何か？`}</li>
      <li parentName="ul">{`アイディアを具現化する際の困難さは何か？`}</li>
      <li parentName="ul">{`何を評価しているのか？`}</li>
      <li parentName="ul">{`問題・アイディア・評価はきちんと対応づいているか？`}</li>
      <li parentName="ul">{`何が嬉しいのか？（有用性はなにか？）`}</li>
      <li parentName="ul">{`アイディアはどの問題のどの範囲まで有効なのか？アイディアの限界を客観的に論じているか？`}</li>
      <li parentName="ul">{`従来研究と公平に比較しているか？`}</li>
      <li parentName="ul">{`比較している従来研究に漏れはないか？`}</li>
    </ul>
    <h1>{`まとめ`}</h1>
    <p>{`今回は、メドレー開発本部の技術勉強会（TechLunch）で発表した内容を紹介しました。
スタートアップで働くエンジニアには普段あまり馴染みがない学術論文ですが、技術で勝負するスタートアップの技術者にこそ学術論文を読むことは必要だと思っています。
いきなり英語で読むのも辛いという方は、日本語の学術論文から読み始めるのもありだと思いますので、これまで読んだことがない方はぜひ読んでみてください。`}</p>
    <p>{`メドレー開発本部で実施している「TechLunch」での発表内容は今後も定期的に紹介していくので、是非メドレーブログをチェックしてみてください！`}</p>
    <p>{`※`}<a parentName="p" {...{
        "href": "https://www.facebook.com/medley.jp/"
      }}>{`メドレー公式 Facebook ページ`}</a>{`に「いいね！」していただけると、ブログの最新情報をフォローできます
今後とも、よろしくお願いします！`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      